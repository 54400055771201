import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, Subject, catchError, from, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { RbacInfo } from "../models/modules.model";
import { SharedService } from "./shared.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuardService implements CanActivate {
    private httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Ocp-Apim-Subscription-Key', environment.btlSubscriptionKey);

    constructor(private http: HttpClient, private router: Router, private sharedService: SharedService,) { }

    canActivate(): boolean {
        // this.getPermissions();
        //if (environment.environment === "local") return true;
        this.isLoggedIn().subscribe((response: boolean) => {
            if (!response) {
                this.router.navigate(["unauthorized"]);
                return false;
            }
            else {
                return true;
            }
        });
        return true;
    }

    isLoggedIn(): Observable<boolean> {
        return from(
            this.http.get(environment.checkAccessUrl).toPromise()
                .then((response: any) => {
                    if (response?.statusMessage === "authorized") {
                        window.sessionStorage.setItem("jwt_token", response?.jwtToken);
                        this.sharedService.setUserInfo(response?.userInfo);
                        this.sharedService.setAdGroups(response?.adGroups);                    
                        this.getPermissions(response?.adGroups);
                        return true;
                    }
                    return false;
                })
                .catch(() => {
                    return false;
                })
        );
    }

    getPermissions(adGroups: string[]): void {
        this.getAccessControlPermissions(adGroups).subscribe((rbacInfo: RbacInfo) => {
            if (rbacInfo) {
                rbacInfo.permissions = [...new Set(rbacInfo.permissions)];
                this.sharedService.setPermissions(rbacInfo);
            }
        });
    }

    getAccessControlPermissions(adGroups: string[]): Observable<RbacInfo> {
        let groups = adGroups.map(item => item.substring(3));
        let requestBody = {
            "bemsId": "string",
            "adGroups": groups
        }
        return this.http.post<RbacInfo>(
            `${environment.backendBaseUrl}/access-control`,
            requestBody,
            { headers: this.httpHeaders }
        );
    }
}

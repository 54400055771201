<div class="group-3">
    <div class="col-2 close-div">
        <i class="material-icons-outlined cross" mat-dialog-close>close</i>
    </div>

    <div class="group-2">
        <p class="metadata">Metadata</p>
    </div>

    
    <div class="form-full-width mb-2 form-block single" >
        <div class="form-header ">Filename</div>
        <div class="subtitle-1 ">{{ fileName }} </div>
    </div>

    <div class="form-full-width mb-2 form-block single">
        <div class="form-header">Artifact ID</div>
        <div class="subtitle-1">{{ artifactId }}</div>
    </div>

    
     <div class="flex-form"  >
        <div class="form-full-width mb-2 form-block" *ngFor="let controlName of propertyForm.controls | keyvalue , let i = index">
            <div class="form-header" >{{ controlName.key | formatControlName}}</div>
            <div class="subtitle-1">{{ controlName.value.value }}</div>
        </div>
    </div>

    <div class="keywords">
        <div class="subtitle-1">Keywords</div>
        <div class="key">            
            <div *ngFor = "let item of keywords" class="group-11 key-block">{{ item }} </div>
        </div>
    </div>

    <button class="primary close" (click)="close()">Close</button>
</div>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { StylePaginatorDirective } from './directives/style-paginator.directive';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { ContractsComponent } from './components/contracts/contracts.component';
import { ApprovalsComponent } from './components/approvals/approvals.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { AccessControlDirective } from './directives/access-control.directive';
import { HistoryComponent } from './components/history/history.component';
import { BtlSharedLibraryModule } from 'btl-shared-library';
import { ArchiveDialogComponent } from './components/archive-dialog/archive-dialog.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { TimeZonePipe } from './utils/timezone.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MetadataDialogComponent } from './components/metadata-dialog/metadata-dialog.component';
import { PaginationComponent } from './components/pagination/pagination.component';

import { MatIconModule } from '@angular/material/icon';
import { FormatControlNamePipe } from './pipes/format-control-name-pipe';
import { AddEntityDialogComponent } from './components/add-entity-dialog/add-entity-dialog.component';
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    StylePaginatorDirective,
    UnauthorizedComponent,
    SpinnerOverlayComponent,
    ContractsComponent,
    ApprovalsComponent,
    AccessControlDirective,
    HistoryComponent,
    ArchiveDialogComponent,
    TimeZonePipe,
    MetadataDialogComponent,
    PaginationComponent,
    FormatControlNamePipe,
    AddEntityDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatRadioModule,
    HttpClientModule,
    AutocompleteLibModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDividerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatExpansionModule,
    FormsModule,
    MatSnackBarModule,
    BtlSharedLibraryModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatIconModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="pagination">
    <button [disabled]="value.page === 1" (click)="selectPage(1)" class="arrow-button"><<</button>
    <button [disabled]="value.page === 1" (click)="selectPage(value.page - 1)" class="arrow-button">
      <
    </button>
    <button
      *ngFor="let page of visiblePages"
      [ngClass]="value.page === page ? 'selected': ''"
      (click)="selectPage(page)"
    >
      {{ page }}
    </button>
    <button
      [disabled]="value.page === totalPages"
      (click)="selectPage(value.page + 1)"
      class="arrow-button"
    >
      >
    </button>
    <button
      [disabled]="value.page === totalPages"
      (click)="selectPage(totalPages)"
      class="arrow-button"
    >
      >>
    </button>
  </div>
  
  <!-- <select [ngModel]="value.pageSize" (ngModelChange)="selectPageSize($event)">
    <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
  </select> -->
  
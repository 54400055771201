<h1 mat-dialog-title>Add {{isModuleContract ? "New Entity" : "" }} Artifact</h1>
<div mat-dialog-content>
    <div class="d-flex justify-content-end section-container">
        <form [formGroup]="reactiveForm">
            <!-- <mat-form-field class="form-full-width mb-4" *ngIf="isModuleContract">
                <mat-label class="form-header">Entity RENEW</mat-label>
                <input matInput placeholder="Ex. SPJ" formControlName="airline">
            </mat-form-field> -->
            <mat-form-field class="form-full-width mb-4" *ngIf="isModuleContract">
                <mat-label class="form-header">Entity</mat-label>
                <mat-select formControlName="airline" class="dialog-select">
                    <mat-option *ngFor="let value of airlines" [value]="value.airlineCode">
                        {{value.airlineCode}} - {{value.airlineName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="form-row">
                <mat-form-field class="form-full-width">
                    <mat-label class="form-header">Filename</mat-label>
                    <input matInput placeholder="Ex. Alaska-Airlines.ppt" formControlName="name">
                </mat-form-field>
                <mat-form-field class="form-full-width revision-field">
                    <mat-label class="form-header">Revision</mat-label>
                    <input matInput placeholder="Ex. 1.0" formControlName="revision">
                </mat-form-field>
            </div>
            <span class="or-text">OR SELECT</span>
            <mat-form-field class="form-full-width">
                <mat-label class="form-header">Artifact ID</mat-label>
                <input matInput placeholder="Ex. AA_FTDOC_abcd:1.0" formControlName="artifact">
            </mat-form-field>

            <mat-form-field *ngIf="isModuleContract" class="form-full-width datepicker-field mt-3">
                <mat-label class="form-header">Contract Validity</mat-label>
                <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="MM/DD/YYYY">
                    <input matEndDate formControlName="end" placeholder="MM/DD/YYYY">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="dateRange.controls['start'].hasError('matStartDateInvalid')">Invalid start date
                </mat-error>
                <mat-error *ngIf="dateRange.controls['end'].hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>

            <div class="text-danger"
                *ngIf="showErrorMessage && (reactiveForm?.get('name')?.dirty || reactiveForm?.get('revision')?.dirty) && reactiveForm?.get('artifact')?.dirty">
                Please enter the filename and revision or Artifact ID
            </div>
        </form>

    </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button (click)="onNoClick()" class="btn-cancel mx-4">Cancel</button>
    <button mat-button [mat-dialog-close]="airlineControl.value" cdkFocusInitial class="btn-submit"
        [disabled]="!showSubmitButton || dateRange.invalid" (click)="onSubmit()">ADD +</button>
</div>

import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Modules, RbacInfo } from '../models/modules.model';
import { AuthGuardService } from '../services/auth-guard.service';
import { SharedService } from '../services/shared.service';

@Directive({
  selector: '[appAccessControl]'
})
export class AccessControlDirective implements AfterViewInit {

  @Input("moduleType") moduleType!: Modules;
  @Input("behavior") behavior: string = "remove";

  private accessControls!: RbacInfo;

  constructor(private elementRef: ElementRef, private renderer2: Renderer2, private sharedService: SharedService) { }

  ngAfterViewInit(): void {
    this.sharedService.permissions$.subscribe((rbacInfo: RbacInfo) => {
      this.accessControls = rbacInfo;
      // this.checkAccess();
      // if (this.behavior.toLowerCase() == "hide") {
      //   this.userHasRequiredAccess() ? this.showElement() : this.hideElement();
      // }

      if (this.behavior.toLowerCase() == "disable") {
        this.userHasRequiredAccess() ? this.enableElement() : this.disableElement();
      }

      if (this.behavior.toLowerCase() == "remove" && !this.userHasRequiredAccess()) {
        this.elementDisplayNone();
      }
    });
  }

  userHasRequiredAccess() {
    return this.accessControls?.permissions.indexOf(Modules[this.moduleType]) != -1
  }

  private elementDisplayNone(): void {
    this.renderer2.removeChild(document, this.elementRef.nativeElement)//.setStyle(this.elementRef.nativeElement, "display", "none");
  }

  private disableElement(): void {
    this.elementRef.nativeElement.disabled = true;
  }

  private enableElement(): void {
    this.elementRef.nativeElement.disabled = false;
  }

  // checkAccess() {
  //   const isAccessExist: any = this.accessControls?.permissions.indexOf(Modules[this.moduleType]) != -1;
  //   this.elementRef.nativeElement.style.display = isAccessExist ? "block" : "none";
  // }

}

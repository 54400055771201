import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, map, startWith } from 'rxjs';


export interface DialogData {
  module: string;
  entities: [];
}

export enum Modules {
  approvals,
  contracts,
}

@Component({
  selector: 'app-add-entity-dialog',
  templateUrl: './add-entity-dialog.component.html',
  styleUrls: ['./add-entity-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEntityDialogComponent implements OnInit {
  form: FormGroup | undefined;
  reactiveForm: FormGroup;
  dateRange: FormGroup;
  airlineControl = new FormControl('');
  group1Control = new FormControl('');
  options: string[] = [];
  airlineOptions: Observable<string[]> | undefined;
  showErrorMessage: boolean = false;
  showSubmitButton: boolean = false;
  fileName: any;
  entity: any;
  isModuleContract: boolean;
  isModuleApproval: boolean;
  airlines: any = [];

  constructor(
    public dialogRef: MatDialogRef<AddEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _fb: FormBuilder
  ) {
    this.reactiveForm = this._fb.group({
      name: [{ value: '', disabled: false }],
      revision: [''],
      artifact: [''],
      airline: [
        '',
        data.module === Modules[Modules.contracts] && Validators.required,
      ],
    });
    this.dateRange = this._fb.group({
      start: [null],
      end: [null],
    });
    this.reactiveForm.addControl('dateRange', this.dateRange);
    this.reactiveForm.valueChanges.subscribe(() => {
      this.updateErrorMessageFlag();
      this.updatesubmitButton();
    });
    this.isModuleContract = data.module === Modules[Modules.contracts];
    this.isModuleApproval = data.module === Modules[Modules.approvals];
    this.airlines = data.entities;
  }

  ngOnInit(): void {
    this.airlineOptions = this.airlineControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateErrorMessageFlag() {
    const nameControl = this.reactiveForm.get('name');
    const revisionControl = this.reactiveForm.get('revision');
    const artifactControl = this.reactiveForm.get('artifact');

    const name = nameControl ? nameControl.value : null;
    const revision = revisionControl ? revisionControl.value : null;
    const artifact = artifactControl ? artifactControl.value : null;

    this.showErrorMessage = (artifact && name) || (artifact && revision);
  }
  updatesubmitButton() {
    const nameControl = this.reactiveForm.get('name');
    const revisionControl = this.reactiveForm.get('revision');
    const artifactControl = this.reactiveForm.get('artifact');
    const airlineNameControl = this.reactiveForm.get('airline');

    const name = nameControl ? nameControl.value : null;
    const revision = revisionControl ? revisionControl.value : null;
    const artifact = artifactControl ? artifactControl.value : null;
    const airline = airlineNameControl ? airlineNameControl.value : null;

    this.showSubmitButton = this.isModuleApproval
      ? (!name && !revision && artifact) || (name && revision && !artifact)
      : (!name && !revision && artifact && airline) ||
      (name && revision && !artifact && airline);
  }

  onSubmit() {
    if (this.reactiveForm.valid) {
      const nameControl = this.reactiveForm.get('name');
      const revisionControl = this.reactiveForm.get('revision');
      const artifactControl = this.reactiveForm.get('artifact');
      const airlineNameControl = this.reactiveForm.get('airline');
      const startDateControl = this.dateRange.get('start');
      const endDateControl = this.dateRange.get('end');

      const name = nameControl ? nameControl.value : null;
      const revision = revisionControl ? revisionControl.value : null;
      const artifact = artifactControl ? artifactControl.value : null;
      const airline = airlineNameControl ? airlineNameControl.value : null;
      const startDate = startDateControl ? startDateControl.value : null;
      const endDate = endDateControl ? endDateControl.value : null;
      // Preparing the request body
      const requestBody: any = this.isModuleApproval ? { "trainingEntityCode": "" } : {
        airlineCode: airline,
        validFrom: startDate,
        validTo: endDate,
        keepWithLatest: true,
        createdBy: 'BTL',
        updatedBy: 'BTL',
        active: true,
      };

      // Conditionally add fileName to the request body if it's not null
      if (this.isModuleContract && name && revision && !artifact) {
        requestBody.fileName = this.reactiveForm.get('name')?.value;
        requestBody.revision = this.reactiveForm.get('revision')?.value;
        requestBody.contentKey = '';
      }
      else if (this.isModuleApproval && name && revision && !artifact) {
        requestBody.fileName = this.reactiveForm.get('name')?.value;
        requestBody.revision = this.reactiveForm.get('revision')?.value;
        requestBody.artifactKey = "";
      }

      // Conditionally add artifact to the request body if it's not null
      if (this.isModuleContract && !name && !revision && artifact) {
        requestBody.contentKey = this.reactiveForm.get('artifact')?.value;
      }
      else if (this.isModuleApproval && !name && !revision && artifact) {
        requestBody.artifactKey = this.reactiveForm.get('artifact')?.value;
      }
      this.dialogRef.close({ requestBody });
    }
  }
}

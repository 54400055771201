<div class="outer-section sticky-top">
    <div class="row">
        <div *ngIf="this.type=='CONTRACT' || this.type=='APPROVAL'" class="col-10">
            <label mat-dialog-title class="history-title">{{this.title}}</label>
            <div>
                <p mat-dialog-title class="filename-ar">
                    <span *ngIf="this.type=='CONTRACT'">Entity: {{this.entity}}<br /></span>
                    Artifact ID: {{this.artifactId}}<br />
                    Artifact: {{this.fileName}}<br />
                    Revision: {{this.artifactId ? this.artifactId.split(':')[1] : ''}}</p>
            </div>
        </div>
        <div class="col-2 close-div">
            <i class="material-icons-outlined" mat-dialog-close>close</i>
            <button class="export-btn" (click)="exportToCsv()" *ngIf="shouldExportDisplay">Export to csv</button>
        </div>
    </div>
</div>
<div class="outer-table">
    <table mat-table [dataSource]="dataSource" class="commitment-table" matSort
        matSortActive="sysCreatedDate" matSortDisableClear matSortDirection="desc">

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
        <!-- Update Column -->
        <ng-container matColumnDef="Update">
            <th mat-header-cell *matHeaderCellDef>Update</th>
            <td mat-cell *matCellDef="let element"> {{element.actionType}} </td>
        </ng-container>

        <!-- User Column -->
        <ng-container matColumnDef="User">
            <th mat-header-cell *matHeaderCellDef>User</th>
            <td mat-cell *matCellDef="let element">
                {{element.createdBy}}
            </td>
        </ng-container>

        <!-- Time Column -->
        <ng-container matColumnDef="Time">
            <th mat-header-cell *matHeaderCellDef>Time {{timezone}}</th>
            <td *matCellDef="let element"> {{element.sysCreatedDate | timeZonePipe | date:'yyyy-MM-dd hh:mm a'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<mat-paginator *ngIf="dataLoad" #pagination (page)="pageChangeEvent($event)" [length]="pageLength" [pageSize]="pageSize"
    [showTotalPages]="3" style-paginator [pageSizeOptions]="[3, 5, 10, 20]">
</mat-paginator>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HistoryLogService {

    private httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Ocp-Apim-Subscription-Key', environment.btlSubscriptionKey);

    constructor(private http: HttpClient) { }

    newRecordAddedLog(requestBody: any): Observable<any> {
        let url = `${environment.backendBaseUrl}/history`;
        return this.http.post(url, requestBody, { headers: this.httpHeaders });
    }

}

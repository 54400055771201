export const environment = {
    production: false,
    environment: "development",
    btlSubscriptionKey: '52d2cc9f27824151b693024a22699587',
    backendBaseUrl: 'https://catalystdev.bcnl.digitalaviationservices.com/commitment/api',
    checkAccessUrl: '/api/check-access',
    RoleAppAdmin: "fp_btl_commitment_application_admin_dev",
    RoleContractEditor: "fp_btl_commitment_contract_editor_dev",
    RoleArtifactApprover: "fp_btl_commitment_artifact_approver_dev",
    RoleReadOnly: "fp_btl_commitment_readonly_dev",
    backendCMSBaseURL: 'https://catalystdev.bcnl.digitalaviationservices.com/cloud-cms-service/api',
  backendCMSBaseSubscriptionKey: 'f44bd412dcf449b8b975ba74d7e6108b'
  };
<div style="margin-top: 10px;" class="table-flex-control-container">
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" class="search-input">
    <!-- <i class="material-icons search-magnifier">search</i> -->
    <div class="column-filter-section">
        <span class="column-filter-section-label">Filter By: </span>
        <div *ngFor="let item of mapColumns | keyvalue: configurePopupOrder; let i=index; last as isLast">
            <span *ngIf="item.value">
                <span *ngIf="this.mapDisplayColumnNames.get(item.key) != 'Actions' && this.mapDisplayColumnNames.get(item.key) != 'Start'
                && this.mapDisplayColumnNames.get(item.key) != 'End'">
                    <!-- <span>{{this.mapDisplayColumnNames.get(item.key)}}<mat-icon>keyboard_arrow_down</mat-icon></span> -->
                    <form [formGroup]="filterForm">
                        <mat-form-field appearance="fill" class="filter_form_field">
                            <span [ngClass]="isColumnFilterSelected(item.key) ? 'select-header-bold' : ''">{{this.mapDisplayColumnNames.get(item.key)}}<mat-icon class="arrow_down_icon">keyboard_arrow_down</mat-icon></span>
                            <mat-select multiple formControlName={{item.key}} (selectionChange)="filterEvent($event, item.key)">
                                <mat-option *ngFor="let value of getUniqueFilterOptionsBasedOnKey(item)" [value]="value" (onSelectionChange)="checkBoxSelection($event, item.key)" [id]="getStringType(value)">
                                    {{value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </span>
            </span>
        </div>
    </div>
    <button appAccessControl [moduleType]="modules.entitlement_addnewrow" (click)="openDialog()" *ngIf="!isReadOnly"
        class="btn-configure btn-add-new-row center-content" id="btn-add-new-row"><i
            class="material-icons-outlined">add</i><span>Add New
            Row</span></button>
    <button class="btn-configure center-content" (click)="toggleConfigPopup($event)"><i
            class="material-icons-outlined">tune</i><span>Configure</span></button>    
    <div class="configure-popup" (click)="$event.stopPropagation()" *ngIf="showConfigPopup">
        <table class="configure-table">
            <tr *ngFor="let item of mapColumns | keyvalue: configurePopupOrder; let i=index; last as isLast">
                <td>
                    <mat-checkbox [checked]="item.value" (change)="configureCheckToggle(item.key, item.value)">
                    </mat-checkbox>
                    <span class="px-2">{{this.mapDisplayColumnNames.get(item.key)}}</span>
                </td>
            </tr>
        </table>
    </div>
</div>
<div class="filter-selections-container">
    <!-- <button *ngFor="let item of filterChipMap | keyvalue">{{item.key}}</button> -->
    <div class="pill" *ngFor="let item of filterChipMap | keyvalue">
        <span>{{item.key}}</span>
        <button class="close-btn" (click)="removePill(item.key)">&times;</button>
    </div>
    <button mat-button color="primary" *ngIf="isAnyFilterSelected()" (click)="clearFilter()">Clear All</button>
</div>
<section class="outer-section responsive">
    <table mat-table [dataSource]="dataSource" class="commitment-table" matSort matSortActive="filename"
        matSortDisableClear matSortDirection="desc">

        <ng-container matColumnDef="entity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Entity </th>
            <!-- <td mat-cell *matCellDef="let element"> <i class="material-icons search-magnifier">fitbit</i> </td> -->
            <td mat-cell *matCellDef="let element"> {{element.entity}} </td>
        </ng-container>


        <ng-container matColumnDef="filename">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="width: 250px;"> Artifact </th>
            <td mat-cell *matCellDef="let element" style="width: 250px;"> {{element.filename}} </td>
        </ng-container>


        <ng-container matColumnDef="revision">
            <th mat-header-cell *matHeaderCellDef style="width: 100px;"> Revision </th>
            <td mat-cell *matCellDef="let element"> {{element.revision}} </td>
        </ng-container>

        <ng-container matColumnDef="artifactid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Artifact ID </th>
            <td mat-cell *matCellDef="let element"> {{element.artifactid}} </td>
        </ng-container>

        <ng-container matColumnDef="docType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="width: 120px;"> Doc Group </th>
            <td mat-cell *matCellDef="let element"> {{element.docType}} </td>
        </ng-container>

        <ng-container matColumnDef="contract">
            <th mat-header-cell *matHeaderCellDef>Access</th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" [checked]="row.contract"
                    [disabled]="isAccessDisabled(row.archived)" (change)="onAccessCheckboxChange(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="courseid" *ngIf=false>
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Course ID </th>
            <td mat-cell *matCellDef="let element"> {{element.courseid}} </td>
        </ng-container>

        <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef disableClear> Start </th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field appearance="outline">
                    <input matInput [matDatepicker]="startPicker" [(ngModel)]="element.validFrom"
                        (dateChange)="updateStartDate(element, $event.value)"
                        [disabled]="isStartDateDisabled() || element.archived">
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef disableClear> End </th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field appearance="outline">
                    <input matInput [matDatepicker]="endPicker" [(ngModel)]="element.validTo" [min]="element.validFrom"
                        (dateChange)="updateEndDate(element, $event.value)"
                        [disabled]="isEndDateDisabled() || element.archived">
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
            </td>
        </ng-container>



        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
                <div class="d-flex actions-div">
                    <i class="material-icons-outlined" (click)="openHistoryDialog(row)">history</i>
                    <i class="material-icons-outlined" (click)="openMetadataDialog(row)">info</i>
                    <i class="material-icons-outlined" (click)="OpenArchiveDialog(row)"
                        [class.disabled]="row.archived">archive</i>
                    <i class="material-icons-outlined" (click)="onClickRowDetailsCopyAction(row)">file_copy</i>
                    <div *ngIf="this.downloadRow==undefined && !displayDownloadingIcon">
                        <i class="material-icons-outlined" (click)="onClickFileAction(row)"
                            [class.disabled]="row.archived">download</i>
                    </div>
                    <div *ngIf="this.downloadRow!=undefined && displayDownloadingIcon">
                        <div *ngIf="this.downloadRow==row.artifactid+'-'+row.entity"
                            [matTooltip]="downloadProgressStatus" matTooltipClass="tooltip-pd" matTooltipPosition="left"
                            class="downloading-spinner">
                            <div *ngIf="percentDone > 0;then downloading else downloadStarting"></div>
                            <ng-template #downloading>
                                <mat-progress-spinner mode="determinate" color="primary" [diameter]="22"
                                    [value]="percentDone"></mat-progress-spinner>
                            </ng-template>
                            <ng-template #downloadStarting>
                                <mat-progress-spinner mode="indeterminate" color="primary"
                                    [diameter]="22"></mat-progress-spinner>
                            </ng-template>
                        </div>
                        <div *ngIf="this.downloadRow!=row.artifactid+'-'+row.entity">
                            <i class="material-icons-outlined" (click)="onClickFileAction(row)"
                                [class.disabled]="row.archived">download</i>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</section>



<mat-paginator *ngIf="dataLoad" (page)="pageChangeEvent($event)" [length]="pageLength" [pageSize]="pageSize"
    [showTotalPages]="3" style-paginator [pageSizeOptions]="[3, 5, 10, 20]">
</mat-paginator>

export enum Modules {
    approvals,
    contracts,

    // approval tab permissions
    approval_addnewrow,
    approval_approved,
    approval_fresh,

    // entitlement tab permissions
    entitlement_date,
    entitlement_access,
    entitlement_addnewrow
}

export interface RbacInfo {
    groups: string[];
    permissions: string[];
}

export interface UserInfo {
    email: string;
    bemsid: string;
    firstname: string;
    lastname: string;
}
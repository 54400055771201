export enum Access {
    read_action,
    create_action,
    delete_action,
    update_action
}

export enum RESULT_RESPONSE {
    DOWNLOAD_STARTED = 'DOWNLOAD_STARTED',
    DOWNLOAD_COMPLETED = 'DOWNLOAD_COMPLETED',
    DOWNLOAD_FAILED = 'DOWNLOAD_FAILED'
}

export class DownloadProgress {
    percentDone: number = 0;
    loaded: string = "";
    total: string = "";
}

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { RbacInfo } from 'src/app/models/modules.model';
import { SharedService } from 'src/app/services/shared.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  accessControls!: RbacInfo;
  isContractEnabled: boolean = false;
  isApprovalEnabled: boolean = false;
  isAdmin: boolean = false;
  isReadOnly: boolean = false;

  constructor(private sharedService: SharedService) { }

  ngAfterViewInit() {
    this.sharedService.permissions$.subscribe((rbacInfo: RbacInfo) => {
      this.accessControls = rbacInfo;
    });

    this.sharedService.adGroups$.subscribe(() => {
      this.isAdmin = this.sharedService.IsUserAdmin();
      this.isReadOnly = this.sharedService.IsUserReadOnly();
      this.isContractEnabled = this.isReadOnly || this.isAdmin || this.sharedService.IsUserContractEditor();
      this.isApprovalEnabled = this.isReadOnly || this.isAdmin || this.sharedService.IsUserArtifactApprover();
    })

  }

  ngOnInit(): void {
  }
}


import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = this.setAuthorizationHeader(request);
    return next.handle(request);
  }

  private setAuthorizationHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const token = window.sessionStorage.getItem("jwt_token") || "";
    request = request.clone({
      setHeaders: {
        "Jwt-Token": token
      }
    });
    return request;
  }
}
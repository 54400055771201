<div style="margin-top: 10px;" class="table-flex-control-container">
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" class="search-input" ng-trim="false">
    <div class="column-filter-section">
        <span class="column-filter-section-label">Filter By: </span>
        <div *ngFor="let item of mapColumns | keyvalue: configurePopupOrder; let i=index; last as isLast">
            <span *ngIf="item.value">
                <span *ngIf="this.mapDisplayColumnNames.get(item.key) != 'Actions'">
                    <form [formGroup]="filterForm">
                        <mat-form-field appearance="fill" class="filter_form_field">
                            <span
                                [ngClass]="isColumnFilterSelected(item.key) ? 'select-header-bold' : ''">{{this.mapDisplayColumnNames.get(item.key)}}<mat-icon
                                    class="arrow_down_icon">keyboard_arrow_down</mat-icon></span>
                            <mat-select multiple formControlName={{item.key}}
                                (selectionChange)="filterEvent($event, item.key)">
                                <mat-option *ngFor="let value of getUniqueFilterOptionsBasedOnKey(item)" [value]="value"
                                    (onSelectionChange)="checkBoxSelection($event, item.key)"
                                    [id]="getStringType(value)">
                                    {{value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </span>
            </span>
        </div>
    </div>
    <button class="btn-configure center-content" (click)="toggleConfigPopup($event)"><i
            class="material-icons-outlined">tune</i><span>Configure</span></button>
    <div class="configure-popup" (click)="$event.stopPropagation()" *ngIf="showConfigPopup">
        <table class="configure-table">
            <tr *ngFor="let item of mapColumns | keyvalue: configurePopupOrder; let i=index; last as isLast">
                <td>
                    <mat-checkbox [checked]="item.value" (change)="configureCheckToggle(item.key, item.value)">
                    </mat-checkbox>
                    <span class="px-2">{{this.mapDisplayColumnNames.get(item.key)}}</span>
                </td>
            </tr>
        </table>
    </div>
</div>
<div class="filter-selections-container">
    <div class="pill" *ngFor="let item of filterChipMap | keyvalue">
        <span>{{item.key}}</span>
        <button class="close-btn" (click)="removePill(item.key)">&times;</button>
    </div>
    <button mat-button color="primary" *ngIf="isAnyFilterSelected()" (click)="clearFilter()">Clear All</button>
</div>
<section class="outer-section responsive">
    <table mat-table [dataSource]="VisibleDataSource" class="commitment-table" matSort matSortActive="filename"
        matSortDisableClear matSortDirection="desc">


        <ng-container matColumnDef="filename" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="width: 250px;"> Artifact </th>
            <td mat-cell *matCellDef="let element" style="width: 250px;"> {{element.filename}} </td>
        </ng-container>

        <ng-container matColumnDef="revision" sticky>
            <th mat-header-cell *matHeaderCellDef> Revision </th>
            <td mat-cell *matCellDef="let element"> {{element.revision}} </td>
        </ng-container>

        <ng-container matColumnDef="artifactid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Artifact ID </th>
            <td mat-cell *matCellDef="let element"> {{element.artifactid}} </td>
        </ng-container>

        <ng-container matColumnDef="docType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Doc Group </th>
            <td mat-cell *matCellDef="let element"> {{element.docType}} </td>
        </ng-container>

        <ng-container matColumnDef="mappedEntities">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Entity </th>
            <td mat-cell *matCellDef="let element"> {{element.mappedEntities}} </td>
        </ng-container>

        <ng-container *ngFor="let header of approvalHeaders" [matColumnDef]="header.toLowerCase()">
            <th mat-header-cell *matHeaderCellDef>{{ header }}</th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox *ngIf="shouldDisplayCheckbox(row, header)" [checked]="isCheckboxTicked(row,header)"
                    [disabled]="isReadOnly" (click)="$event.stopPropagation()" name="onCheckboxChange"
                    (change)="onCheckboxChange(row, row.artifactid, header)">
                </mat-checkbox>
            </td>

        </ng-container>




        <ng-container matColumnDef="fresh" *ngIf="shouldFreshDisplay">
            <th mat-header-cell *matHeaderCellDef>Fresh</th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="row.fresh" [disabled]="isFreshDisabled(row.isLatest)"
                    (click)="$event.stopPropagation()" (change)="onFreshCheckboxChange(row)" name="freshCheckBox">
                </mat-checkbox>
            </td>
        </ng-container>



        <ng-container matColumnDef="courseid" *ngIf=false>
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Course ID </th>
            <td mat-cell *matCellDef="let element"> {{element.courseid}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
                <div class="d-flex actions-div">
                    <i class="material-icons-outlined historyBtn" (click)="openHistoryDialog(row)">history</i>
                    <i class="material-icons-outlined" (click)="openMetadataDialog(row)">info</i>
                    <i class="material-icons-outlined" (click)="onClickRowDetailsCopyAction(row)">file_copy</i>
                    <div *ngIf="this.downloadId==undefined && !displayDownloadingIcon">
                        <i class="material-icons-outlined" (click)="onClickFileAction(row)">download</i>
                    </div>
                    <div *ngIf="this.downloadId!=undefined && displayDownloadingIcon">
                        <div *ngIf="this.downloadId==row.artifactid" [matTooltip]="downloadProgressStatus"
                            matTooltipClass="tooltip-pd" matTooltipPosition="left" class="downloading-spinner">
                            <div *ngIf="percentDone > 0;then downloading else downloadStarting"></div>
                            <ng-template #downloading>
                                <mat-progress-spinner mode="determinate" color="primary" [diameter]="22"
                                    [value]="percentDone"></mat-progress-spinner>
                            </ng-template>
                            <ng-template #downloadStarting>
                                <mat-progress-spinner mode="indeterminate" color="primary"
                                    [diameter]="22"></mat-progress-spinner>
                            </ng-template>
                        </div>
                        <div *ngIf="this.downloadId!=row.artifactid">
                            <i class="material-icons-outlined" (click)="onClickFileAction(row)">download</i>
                        </div>
                    </div>
                </div>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>

</section>

<div class="pagination-wrapper" >
    <app-pagination [total]="visibleItems.total" [visibleRangeLength]="visibleRangeLength" [formControl]="paginationControl" *ngIf="dataLoad">
    </app-pagination>
</div>

import { Component, OnInit } from '@angular/core';
import { User } from './models/user.model';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'btl-commitment-ui';
  user?: User;
  constructor(private http: HttpClient, private router: Router, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.userInfo$.subscribe((user) => {
      this.user = user
    });
  }

  logout(): void {
  }
}

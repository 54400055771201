import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HistoryElement, HistoryResponse } from 'src/app/models/history-element.model';
import { HistoryMapping } from 'src/app/models/history.model';
import { ExportService } from 'src/app/services/export.service';
import { IntegrationService } from 'src/app/services/integration.service';
import { SharedService } from 'src/app/services/shared.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  ELEMENT_DATA: HistoryElement[] = [];
  mapDisplayColumnNames = new Map();
  mapColumns = new Map();
  dataLoad = false;

  pageSize = 10;
  showPages = 2;
  pageLength = this.pageSize * (this.showPages - 1);
  dataSource: any;
  columns: any[] = ['actionType', 'revision'];
  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  artifactId: string | undefined;
  fileName: string | undefined;
  type: string | undefined;
  title!: string;
  entity!: string;
  timezone!: string;
  shouldExportDisplay: boolean = false;

  displayedColumns = ['Update', 'User', 'Time'];

  sharedData!: HistoryMapping;

  constructor(private integrationService: IntegrationService,
    private spinnerOverlayService: SpinnerOverlayService,
    private sharedService: SharedService,
    private exportService: ExportService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.artifactId = this.data.artifactid
    this.fileName = this.data.filename;
    this.type = this.data.type;
    this.title = this.data.title;
    this.entity = this.data.entity;
  }

  ngOnInit(): void {
    this.loadData();
    this.sharedService.timeZone$.subscribe((zone => {
      this.timezone = zone;
    }))
  }

  loadData(): void {
    this.spinnerOverlayService.show();
    this.integrationService.getAllApprovalHistory(this.artifactId, this.type).subscribe(
      (data: HistoryResponse) => {
        if (data.data.length > 0) {
          let sortedData = data.data.sort((a, b) => new Date(b.sysCreatedDate).getTime() - new Date(a.sysCreatedDate).getTime());
          setTimeout(() => {
          this.dataSource = new MatTableDataSource<HistoryElement>(sortedData);
          this.pageLength = sortedData.length;
          this.showPages = sortedData.length >= 30 ? 3 : sortedData.length >= 20 ? 2 : 1
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.shouldExportDisplay = true;
          this.spinnerOverlayService.hide();
          this.dataLoad = true;
            this.triggerPaginatorFalseClick();
          }, 0);
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinnerOverlayService.hide();
      }
    );
  }

  pageChangeEvent(event: PageEvent) {
    this.dataSource.paginator = this.paginator;
  }

  exportToCsv(): void {
    // Cloning array of objects
    let clonedData = JSON.parse(JSON.stringify(this.dataSource.data));
    var datePipe = new DatePipe("en-US");
    let transformedData = clonedData.map((x: any) => {
      x.sysCreatedDate = datePipe.transform(x.sysCreatedDate, "yyyy-MM-dd hh:mm a")
      return x;
    });
    this.exportService.exportToCsv(transformedData, this.title, ["Updated By", "User", "Time " + this.timezone], ['actionType', 'createdBy', 'sysCreatedDate']);
  }

  triggerPaginatorFalseClick() {
    this.paginator?.nextPage();
    this.paginator?.previousPage();
  }
}


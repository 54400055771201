<h2 class="title">Commitments Service</h2>

<mat-tab-group mat-align-tabs="start" [color]="'warn'">
    <mat-tab label="Artifacts" *ngIf="isApprovalEnabled">
        <app-approvals [isReadOnly]="isReadOnly"></app-approvals>
    </mat-tab>
    <mat-tab label="Entitlements" *ngIf="isContractEnabled">
        <app-contracts [isReadOnly]="isReadOnly"></app-contracts>
    </mat-tab>
    

</mat-tab-group>
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlanService {

  private httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.btlSubscriptionKey);

  constructor(private http: HttpClient) { }

  downloadArtifact(
    artifactVersion: any,
    docType: any
  ): Observable<HttpEvent<any>> {
    let url =
      environment.backendBaseUrl +
      `/artifact-approval/${artifactVersion}/doctype/${docType}/download`;
    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
      headers: this.httpHeaders,
    });
  }
}

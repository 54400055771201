import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IntegrationService } from 'src/app/services/integration.service';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.scss']
})
export class ArchiveDialogComponent implements OnInit {
  currentDate: Date | undefined;

  constructor(public dialogRef: MatDialogRef<ArchiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private integrationService: IntegrationService,
    private spinnerOverlayService: SpinnerOverlayService,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.currentDate = new Date();
    });
  }
  proceed() {
    console.log(this.data.artifactid, this.data.entity, this.data.contract);
    this.spinnerOverlayService.show();
    if (this.data.contract === true) {
      this.integrationService.updateContractCheckboxState(this.data.artifactid, this.data.entity).subscribe(
        response => {
          this.integrationService.archiveContract(this.data.artifactid, this.data.entity).subscribe
            (response => {
              const requestBody = {
                validTo: this.currentDate
              };
              this.integrationService.updateStartandEndDate(this.data.artifactid, this.data.entity, requestBody)
                .subscribe(response => {
                  console.log("validTo Date updated successfully", response)
                  this.spinnerOverlayService.hide();
                }, error => {
                  console.log("Error updating the date", error)
                  this.spinnerOverlayService.hide();
                });
              this.sharedService.triggersubmitEvent();
              console.log("Contract archived successfully", response)
              this.spinnerOverlayService.hide();
            }, error => {
              console.log("Error archiving artifact", error)
              this.spinnerOverlayService.hide();
            });
          console.log("Contract access removed", response)
        },
        error => {
          console.log("Error:", error)
        }
      );
    }
    else {
      this.integrationService.archiveContract(this.data.artifactid, this.data.entity).subscribe
        (response => {
          const requestBody = {
            validTo: new Date()
          };
          this.integrationService.updateStartandEndDate(this.data.artifactid, this.data.entity, requestBody)
            .subscribe(response => {
              console.log("validTo Date updated successfully", response)

              this.spinnerOverlayService.hide();
            }, error => {
              console.log("Error updating the date", error)
              this.spinnerOverlayService.hide();
            });
          this.sharedService.triggersubmitEvent();
          console.log("Contract archived successfully", response)
          this.spinnerOverlayService.hide();
        }, error => {
          console.log("Error archiving artifact", error)
          this.spinnerOverlayService.hide();
        });
    }
    this.sharedService.triggersubmitEvent();
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

}

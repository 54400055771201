import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IntegrationService } from 'src/app/services/integration.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';
import { PeriodicElement } from 'src/app/models/periodic-element.model';
import { PlanService } from 'src/app/services/plan.service';
import { saveAs as importedSaveAs } from "file-saver";
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { Modules, RbacInfo } from 'src/app/models/modules.model';
import { Access, DownloadProgress } from 'src/app/models/access.model';
import { CommonService } from 'src/app/services/common.service';
import { HistoryLogService } from 'src/app/services/history-log.service';
import { HistoryComponent } from '../history/history.component';
import { AddEntityDialogComponent } from '../add-entity-dialog/add-entity-dialog.component';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArchiveDialogComponent } from '../archive-dialog/archive-dialog.component';
import { KeyValue } from '@angular/common';
import { MetadataDialogComponent } from '../metadata-dialog/metadata-dialog.component';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {

  @Input() isReadOnly: boolean = false;

  showConfigPopup = false;
  dataSource: any;
  filterData: any;
  dataLoad = false;
  columns: any[] = [];

  mapDisplayColumnNames = new Map();
  mapColumns = new Map();

  pageSize = 30;
  showPages = 2;
  pageLength = this.pageSize * (this.showPages - 1);
  // pageLength =0;

  selection = new SelectionModel<PeriodicElement>(true, []);
  modules = Modules;
  access = Access
  ELEMENT_DATA: PeriodicElement[] = [];
  entity: string | undefined;
  artifactid: string | undefined;
  revision: string | undefined;
  extension: string = 'description';
  rowData: any | undefined;
  loaded!: string;
  total?: string;
  percentDone!: number;
  downloadProgress: any;
  downloadRow: string | undefined;
  form: FormGroup | undefined;
  configurePopupOrder!: any;
  accessControls!: RbacInfo;
  displayDownloadingIcon: boolean = false;
  downloadProgressStatus: string = "0% Downloaded \nDownload Starting..."
  defaultFilterPredicate?: (data: any, filter: string) => boolean;
  filterForm: FormGroup;
  filterChipMap: Map<string, string> = new Map<string, string>();
  entities: any = [];


  constructor(private integrationService: IntegrationService,
    private spinnerOverlayService: SpinnerOverlayService,
    private planService: PlanService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private historyService: HistoryLogService,
    private sharedService: SharedService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private fb: FormBuilder,
  ) {
    this.initializeForm();
    this.filterForm = this.fb.group({
      selectedOptions: [[]]
    })
  }


  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
    this.showConfigPopup = false;
  }

  ngOnInit(): void {
    this.sharedService.submitEvent$.subscribe(() => {
      this.loadData();
    });
    this.integrationService.getEntities().subscribe((data:any) => {
      if (Array.isArray(data.data.airlineList)) {
        data.data.airlineList.forEach((element: { airlineCode: any; airlineName: any; }) => {
          this.entities.push({airlineCode: element.airlineCode, airlineName: element.airlineName});
        });
      }
    });
    this.loadData();
    this.mapDisplayColumnNames.set('entity', 'Entity');
    // this.mapDisplayColumnNames.set('type', 'Type');
    //this.mapDisplayColumnNames.set('distribution', 'Distribution');
    this.mapDisplayColumnNames.set('filename', 'Artifact');
    // this.mapDisplayColumnNames.set('friendlyname','Friendly Name');
    this.mapDisplayColumnNames.set('revision', 'Revision');
    this.mapDisplayColumnNames.set('artifactid', 'Artifact ID');
    this.mapDisplayColumnNames.set('docType', 'Doc Group');
    // this.mapDisplayColumnNames.set('updatedby', 'Updated By');
    this.mapDisplayColumnNames.set('contract', 'Access');
    this.mapDisplayColumnNames.set('start', 'Start');
    this.mapDisplayColumnNames.set('end', 'End');
    // this.mapDisplayColumnNames.set('ready','Ready');
    // this.mapDisplayColumnNames.set('dpl','DPL');
    // this.mapDisplayColumnNames.set('cfr','CFR');
    // this.mapDisplayColumnNames.set('final','Final');

    // this.mapDisplayColumnNames.set('courseid','Course ID');
    // this.mapDisplayColumnNames.set('draft','Draft');
    this.mapDisplayColumnNames.set('actions', 'Actions');

    this.columns = [...this.mapDisplayColumnNames.keys()];
    let selectedValues: any = {};
    this.columns.map(column => {
      this.mapColumns.set(column, true);
      selectedValues[column] = [[]];
    });
    this.filterForm = this.fb.group(selectedValues);
    this.configurePopupOrder = this.commonService.originalOrder;
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort || null;
    // this.dataSource.paginator = this.paginator || null;
    this.sharedService.permissions$.subscribe((rbacInfo: RbacInfo) => {
      this.accessControls = rbacInfo;
    });
  }

  columnSearch(key: string): boolean {
    const searchableColumns = ['entity', 'artifact', 'artifactid', 'courseid', 'doctype'];
    if (key.indexOf(':') > 0) {
      if (searchableColumns.filter((col) => col.toLowerCase().includes(key.split(':')[0].toLowerCase())).length > 0) {
        return true;
      }
    }
    return false;
  }

  applyFilter(eventOrString?: Event | string) {
    let filterValue: string;
    if (typeof eventOrString === 'string') {
      filterValue = eventOrString;
    } else {
      filterValue = (eventOrString?.target as HTMLInputElement)?.value || '';
    }
    if (this.columnSearch(filterValue)) {
      let columnName = filterValue.split(':')[0].trim().toLowerCase();
      let filteredArray: string[] = filterValue.split(':').slice(1);
      filterValue = filteredArray.length > 1 ? filteredArray.join(':') : filteredArray[0];
      const key = filterValue.trim().toLowerCase();
      this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
        let matchingString = undefined;
        if (columnName == "artifact") {
          matchingString = data.filename;
        } else if (columnName == 'artifactid') {
          matchingString = data.artifactid;
        } else if (columnName == 'entity') {
          matchingString = data.entity;
        } else if (columnName == 'courseid') {
          matchingString = data.courseid;
        } else if (columnName == 'doctype') {
          matchingString = data.docType;
        }
        return matchingString.toLowerCase().indexOf(filter) >= 0;
      }
      this.dataSource.filter = key;
    } else {
      this.dataSource.filterPredicate = this.defaultFilterPredicate;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    setTimeout(() => {
      this.triggerPaginatorFalseClick();
    }, 10);
    this.sharedService.setSearchString(this.dataSource.filter);
  }

  pageChangeEvent(event: PageEvent) {
    this.dataSource.paginator = this.paginator;
  }

  toggleConfigPopup(event: Event) {
    event.stopPropagation();
    this.showConfigPopup = !this.showConfigPopup
  }

  configureCheckToggle(prop: any, flag: any) {
    this.mapColumns.set(prop, !flag);
    this.columns = [...new Map([...this.mapColumns].filter(([key, value]) => value)).keys()];
    if (flag) {
      this.removeAllKeyRelatedToColumn(prop);
    }
  }
  isTeamApproved(approvals: { approved: boolean; approverRole: string }[], team: string): boolean {
    const teamApproval = approvals?.find(approval => approval?.approverRole === team);
    return teamApproval ? teamApproval.approved : false;
  }
  isTeamRequired(approvals: { approved: boolean; approverRole: string }[], team: string): boolean {
    return approvals != undefined ? approvals.some(approval => approval?.approverRole === team) : false;
  }

  loadData(): void {
    this.spinnerOverlayService.show();
    this.integrationService.getAllContracts().subscribe(
      (data) => {
        if (data) {
          this.ELEMENT_DATA = data.data.map((item, index) => ({
            position: index + 1,
            entity: item.airlineCode,
            artifactid: item.artifactId,
            filename: item.fileName.includes('.') ? item.fileName.split('.')[0] + '.' + (item.fileName.split('.')[1].toLowerCase()) : item.fileName,
            docType: item.docType,
            extension: this.commonService.getIconValueFromFilename(item.fileName),
            friendlyname: '737 MAX Post Flight Package',
            courseid: '123456',
            updatedby: "BTL",
            revision: item.artifactId.split(':')[1],
            contract: item.contract,
            readyRequired: this.isTeamRequired(item.approvals, "Ready"),
            readyApproved: this.isTeamApproved(item.approvals, "Ready"),
            dplRequired: this.isTeamRequired(item.approvals, "DPL"),
            dplApproved: this.isTeamApproved(item.approvals, "DPL"),
            dplTypeId: null,
            cfrRequired: this.isTeamRequired(item.approvals, "CFR"),
            cfrApproved: this.isTeamApproved(item.approvals, "CFR"),
            cfrTypeId: null,
            finalRequired: this.isTeamRequired(item.approvals, "Final"),
            finalApproved: this.isTeamApproved(item.approvals, "Final"),
            finalTypeId: null,
            Ready: this.isTeamApproved(item.approvals, "Ready"),
            validFrom: item.validFrom,
            validTo: item.validTo,
            archived: item.archived,
            active: item.active,
            latest: item.latest
          }));
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
          this.pageLength = this.ELEMENT_DATA.length;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.defaultFilterPredicate = this.dataSource.filterPredicate;
          this.filterData = this.dataSource?.filteredData;
        }
        this.spinnerOverlayService.hide();
        setTimeout(() => {
          this.triggerPaginatorFalseClick();
        }, 50);
        this.applyFilter(this.sharedService.getSearchString());
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinnerOverlayService.hide();
      }
    );
    this.dataLoad = true;
  }

  onClickFileAction(row: any) {
    this.downloadRow = `${row.artifactid}-${row.entity}`;
    this.displayDownloadingIcon = true;
    this.loaded = "";
    this.total = "";
    this.percentDone = 0;
    this.planService.downloadArtifact(row.artifactid, row.docType).subscribe(result => {
      this.downloadProgress = new DownloadProgress
      if (result.type === HttpEventType.DownloadProgress) {
        this.total = this.formatBytes(result.total);
        this.loaded = this.formatBytes(result.loaded);
        if (result.total && result.total > 0) {
          this.percentDone = Math.round(100 * result.loaded / result.total);
          this.downloadProgressStatus = this.percentDone + "% Downloaded \n" + this.loaded + " / " + this.total;
        }
      }
      if (result.type === HttpEventType.Response) {
        this.downloadProgress = undefined;
        importedSaveAs(result.body, row.filename);
        this.displayDownloadingIcon = false;
        this.downloadRow = undefined;
        this.downloadProgressStatus = "0% Downloaded \nDownload Starting...";
        let logData = {
          "entity": row.entity ? row.entity : "",
          "filename": row.filename ? row.filename : "",
          "revision": row.artifactid.split(":")[1],
          "artifactId": row.artifactid,
          "actionType": "Artifact downloaded",
          "createdBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
          "modifiedBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
          "type": "CONTRACT"
        };
        this.newHistoryLogRecord(logData);
      }
    },
      error => {
        this.downloadProgressStatus = "0% Downloaded \nDownload Starting...";
        this.displayDownloadingIcon = false;
        this.downloadRow = undefined;
        this.btlSharedLibraryService.updateStatusMessage(true, "Download Failed.", false);
        console.log('ERR', error);
      }
    );
  }

  getUniqueFilterOptionsBasedOnKey(key: any) {
    if (this.filterData) {
      let result = [...new Set(this.filterData.map(function(row: any) {return row[key.key]}))];
      return result;
    }
    return [];
  }

  isColumnFilterSelected(key: any) {
    if (this.filterChipMap.size > 0) {
      for (let val of this.filterChipMap.values()) {
        if (val == key) {
          return true;
        }
      }
    }
    return false;
  }

  filterEvent(event: any, key: String) {
    let dataCopy = this.filterData;
    for (let [key, value] of Object.entries(this.filterForm.value)) {
      const currentDataCopy: any[] = [];
      if (Array.isArray(value) && value.length > 0) {
        value.forEach(filterValue => {
          currentDataCopy.push(...dataCopy.filter((obj: { [x: string]: any; }) => obj[key] == filterValue))
        });
        dataCopy = currentDataCopy;
      }
    }
    this.dataSource.data = dataCopy;
  }

  isAnyFilterSelected() {
    return this.filterChipMap.size > 0;
  }

  checkBoxSelection(event: any, key: string) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.filterChipMap.set(event.source.value, key);
      } else {
        this.filterChipMap.delete(event.source.value);
      }
    }
  }

  removePill(key: any) {
    this.filterChipMap.delete(key);
    this.rearrangeFilter(this.filterChipMap);
  }

  removeAllKeyRelatedToColumn(column: any) {
    this.filterChipMap = new Map([...this.filterChipMap].filter(([key, value]) => value != column));
    this.rearrangeFilter(this.filterChipMap);
  }

  rearrangeFilter(filterChipMapValues: Map<string, string>) {
    let keyValueMap = new Map();
    filterChipMapValues.forEach((value, key) => {
      if (keyValueMap.has(value)) {
        keyValueMap.get(value).push(key);
      } else {
        keyValueMap.set(value, [key]);
      }
    });
    let dataCopy = this.filterData;
    for (let [key, value] of keyValueMap) {
      const currentDataCopy: any[] = [];
      if (Array.isArray(value) && value.length > 0) {
        value.forEach(filterValue => {
          currentDataCopy.push(...dataCopy.filter((obj: { [x: string]: any; }) => obj[key] == filterValue))
        });
        dataCopy = currentDataCopy;
      }
    }
    this.dataSource.data = dataCopy;
  }

  getStringType(val: any) {
    return String(val);
  }

  clearFilter() {
    this.dataSource.data = this.filterData;
    this.filterChipMap.clear();
    for (let [key, value] of this.mapColumns) {
      this.filterForm.controls[key].reset();
    }
  }

  onClickRowDetailsCopyAction(row: any) {
    this.spinnerOverlayService.show();
    let dataToCopy: any = {
      Type: row.filename.split('.').pop(),
      distribution: "Direct",
      filename: row.filename,
      revision: row.revision,
      start: row.validFrom,
      end: row.validTo,
      artifactid: row.artifactid,
    };
    navigator.clipboard.writeText(JSON.stringify(dataToCopy, undefined, '\t'));
    this.btlSharedLibraryService.updateStatusMessage(true, "Row data copied.", true);
    this.spinnerOverlayService.hide();
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(AddEntityDialogComponent, { data: {
       module: Modules[Modules.contracts],
       entities: this.entities
       }
     });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (!result) return;
      this.onSubmit(result);
    });
  }
  onAccessCheckboxChange(row: any) {
    this.rowData = row;
    this.integrationService.updateContractCheckboxState(row.artifactid, row.entity).subscribe(
      response => {
        this.rowData.actionType = row.contract ? "Access column unchecked" : "Access column checked";
        this.checkBoxStateChange(this.rowData);
        this.loadData();
      },
      error => {
      }
    );
  }
  checkBoxStateChange(rowData: any) {
    let logData = {
      "entity": rowData.entity ? rowData.entity : "",
      "filename": rowData.filename ? rowData.filename : "",
      "revision": rowData.artifactid.split(":")[1],
      "artifactId": rowData.artifactid,
      "actionType": rowData.actionType,
      "createdBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
      "modifiedBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
      "type": "CONTRACT"
    };
    this.newHistoryLogRecord(logData);
  }

  newHistoryLogRecord(payload: any) {
    this.historyService.newRecordAddedLog(payload).subscribe(
      (response) => {
        console.log("Successfully Logged");
      },
      (error) => {
        console.log("Logging error!!");
      }
    );
  }

  onSubmit(data: any) {
    this.spinnerOverlayService.show();
    this.integrationService.createContract(data.requestBody).subscribe(
      (data) => {
        // this.commonService.showSuccessSnackBar('New Row Added Successfully');
        this.btlSharedLibraryService.updateStatusMessage(true, "New Row Added Successfully", true);
        this.loadData();
        this.spinnerOverlayService.hide();
        if (data.data) {
          let item = data.data;
          const historyLogData = {
            "entity": data.data.trainingEntityCode ? data.data.trainingEntityCode : "",
            //"filename": data.data.requestBody.fileName ? data.data.requestBody.fileName : "",
            "revision": item.contentKey.split(":")[1],
            "artifactId": item.contentKey,
            "actionType": "New row added",
            "createdBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
            "modifiedBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
            "type": "CONTRACT"
          };
          this.newHistoryLogRecord(historyLogData);
        }
      },
      (error) => {
        // this.commonService.showErrorSnackBar(error.error.message);
        this.btlSharedLibraryService.updateStatusMessage(true, error.error.message, false);
        this.spinnerOverlayService.hide();
      }
    );
    window.scroll(0, 0)
  }

  openHistoryDialog(row: any): void {
    let logData = {
      "artifactid": row.artifactid,
      "filename": row.filename,
      "type": "CONTRACT",
      "title": "Entitlements History",
      "entity": row.entity,
    };
    const dialogRef = this.dialog.open(HistoryComponent, { autoFocus: false, data: logData });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }


  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Byte';

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  stringExists(text: any): boolean {
    return (text != undefined && text.length > 0);
  }

  private initializeForm() {
    this.form = this.fb.group({
      start: ['', Validators.required],
      end: ['', Validators.required]
    }, { validator: this.dateValidator });
  }
  dateValidator(group: FormGroup) {
    const start = group.get('start')?.value;
    const end = group.get('end')?.value;
    if (start && end && start > end) {
      return { invalidRange: true };
    }
    return null;
  }
  updateStartDate(element: any, newDate: Date) {
    this.spinnerOverlayService.show();
    const requestBody = {
      validFrom: newDate
    };
    this.integrationService.updateStartandEndDate(element.artifactid, element.entity, requestBody)
      .subscribe(response => {
        console.log("validFrom Date updated successfully", response)
        this.spinnerOverlayService.hide();
      }, error => {
        console.log("Error updating the date", error)
        this.spinnerOverlayService.hide();
      });
  }
  updateEndDate(element: any, newDate: Date) {
    this.spinnerOverlayService.show();
    const requestBody = {
      validTo: newDate
    };
    this.integrationService.updateStartandEndDate(element.artifactid, element.entity, requestBody)
      .subscribe(response => {
        console.log("validTo Date updated successfully", response)
        this.spinnerOverlayService.hide();
      }, error => {
        console.log("Error updating the date", error)
        this.spinnerOverlayService.hide();
      });
  }

  OpenArchiveDialog(row: any) {
    const dialogRef = this.dialog.open(ArchiveDialogComponent, {
      data: row
    });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  triggerPaginatorFalseClick() {
    this.paginator?.nextPage();
    this.paginator?.previousPage();
    this.paginator?.page.next({
      pageIndex: 1,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    });
  }

  isAccessDisabled(archived: boolean) {
    return this.isReadOnly || archived || this.disablePermissions(this.modules.entitlement_access);
  }

  isStartDateDisabled() {
    return this.disablePermissions(this.modules.entitlement_date);
  }

  isEndDateDisabled() {
    return this.disablePermissions(this.modules.entitlement_date);
  }

  disablePermissions(module: Modules) {
    return this.accessControls?.permissions.indexOf(Modules[module]) === -1
  }

  openMetadataDialog(row: any): void {
    let data = {
      "docType": row.docType,
      "documentid": row.artifactid
    }
    const dialogRef = this.dialog.open(MetadataDialogComponent, { autoFocus: false, data: data });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }
}


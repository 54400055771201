import { Pipe, PipeTransform } from "@angular/core";
import { SharedService } from "../services/shared.service";

@Pipe({
    name: 'timeZonePipe',
})
export class TimeZonePipe implements PipeTransform {
    constructor(private sharedService: SharedService) { }
    transform(value: string) {
        let splittedTime = new Date(value).toString().split("(")[1];
        this.sharedService.setTimeZone("("+splittedTime);
        return value;
    }
}
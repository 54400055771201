import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { commitmentResponse } from '../models/artifact-mapping.model';
import { HistoryResponse } from '../models/history-element.model';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  private httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.btlSubscriptionKey);

  constructor(private http: HttpClient) { }

  getAllAirlineArtifactMapping(): Observable<commitmentResponse> {
    return this.http.get<commitmentResponse>(`${environment.backendBaseUrl}/artifact-approval/listing`, {
      headers: this.httpHeaders,
    });
  }
  getAllContracts(): Observable<commitmentResponse> {
    return this.http.get<commitmentResponse>(`${environment.backendBaseUrl}/contracts`, {
      headers: this.httpHeaders,
    });
  }

  createArtifactApproval(requestBody: any): Observable<any> {
    return this.http.post(
      `${environment.backendBaseUrl}/artifact-approval/create`,
      requestBody,
      { headers: this.httpHeaders }
    );
  }

  createContract(requestBody: any): Observable<any> {
    return this.http.post(
      `${environment.backendBaseUrl}/contracts/add`,
      requestBody,
      { headers: this.httpHeaders }
    );
  }

  updateApprovalCheckboxState(requestBody: any): Observable<any> {
    const url = `${environment.backendBaseUrl}/artifact-approval/approve-group`;
    return this.http.put(url, requestBody, { headers: this.httpHeaders });
  }
  updateContractCheckboxState(artifactid: string, entity: string): Observable<any> {
    const url = `${environment.backendBaseUrl}/contracts/approve-reject/${artifactid}/${entity}`;
    return this.http.put(url, null, { headers: this.httpHeaders });
  }

  updateReadyCheckboxState(artifactId: string): Observable<any> {
    const url = `${environment.backendBaseUrl}/artifact-approval/approve-isReady/${artifactId}`;
    return this.http.put(url, null, { headers: this.httpHeaders });
  }

  getAllApprovalHistory(artifactId: string | undefined, type: string | undefined): Observable<HistoryResponse> {
    return this.http.get<HistoryResponse>(`${environment.backendBaseUrl}/historydetails/${artifactId}/${type}`, {
      headers: this.httpHeaders,
    });
  }

  updateStartandEndDate(artifactid: string, entity: string, requestBody: any): Observable<any> {
    const url = `${environment.backendBaseUrl}/contracts/start-end-date/${artifactid}/${entity}`;
    return this.http.put(url, requestBody, { headers: this.httpHeaders });
  }

  archiveContract(artifactid: string, entity: string):Observable<any> {
    const url = `${environment.backendBaseUrl}/contracts/contract-archive/${artifactid}/${entity}`;
    return this.http.put(url, null, { headers: this.httpHeaders });
  }

  updateFreshCheckboxState(artifactId: string, isFresh: boolean): Observable<any> {
    const url = `${environment.backendBaseUrl}/artifact-approval/approve-isFresh/${artifactId}/${isFresh}`;
    return this.http.put(url, null, { headers: this.httpHeaders });
  }

  getMetaData(docType: string,documentid : string) : Observable<any> {
    const url = `${environment.backendBaseUrl}/artifact/doctype/${docType}/documentid/${documentid}`;
    return this.http.get(url,{
      headers: this.httpHeaders,
    });
  }

  getPropertyDefinitions(docTypeName: string): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Ocp-Apim-Subscription-Key', environment.backendCMSBaseSubscriptionKey);
    return this.http.get<any>(`${environment.backendCMSBaseURL}/lookup/doctypes?docTypeName=${docTypeName}`, {
      headers: httpHeaders,
    });
  }

  getEntities() : Observable<any> {
    const url = `${environment.backendBaseUrl}/airlines`;
    return this.http.get(url,{
      headers: this.httpHeaders,
    });
  }
}

import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IntegrationService } from 'src/app/services/integration.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';
import { PeriodicElement } from 'src/app/models/periodic-element.model';
import { PlanService } from 'src/app/services/plan.service';
import { saveAs as importedSaveAs } from "file-saver";
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { HistoryLogService } from 'src/app/services/history-log.service';
import { Modules, RbacInfo } from 'src/app/models/modules.model';
import { Access, DownloadProgress } from 'src/app/models/access.model';
import { ArtifactMapping, commitmentResponse } from 'src/app/models/artifact-mapping.model';
import { CommonService } from 'src/app/services/common.service';
import { AddEntityDialogComponent } from 'btl-shared-library';
import { HistoryComponent } from '../history/history.component';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { MetadataDialogComponent } from '../metadata-dialog/metadata-dialog.component';
import { FormControl } from '@angular/forms';
import { PaginationValue } from '../pagination/pagination.component';


export interface PaginatedResponse<T> {
  visibleItems: any[];
  total: number;
  data: any[];
}

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss']
})
export class ApprovalsComponent implements OnInit {

  public pagination = { page: 1, pageSize: 5 };

  public readonly paginationControl = new FormControl(this.pagination);

  private readonly items = Array.from(Array(100).keys(), (item) => item + 1);

  public visibleItems: PaginatedResponse<number> = {
    visibleItems: [],
    total: 0,
    data:[],
  };

  @Input() isReadOnly: boolean = false;

  showConfigPopup = false;
  dataSource: any;
  VisibleDataSource: any;
  filterData: any;
  dataLoad = false;
  columns: any[] = [];

  mapDisplayColumnNames = new Map();
  mapColumns = new Map();
  approvalHeaders = new Set<string>();

  pageSize = 7;
  showPages = 2;
  pageLength = this.pageSize * (this.showPages - 1);
  // pageLength =0;

  shouldFreshDisplay = false;
  selection = new SelectionModel<PeriodicElement>(true, []);
  modules = Modules;
  access = Access
  ELEMENT_DATA: PeriodicElement[] = [];
  entity: string | undefined;
  artifactid: string | undefined;
  revision: string | undefined;
  mappedEntities: string | undefined;
  extension: string = "description";
  rowData: any | undefined;
  loaded!: string;
  total?: string;
  percentDone!: number;
  downloadProgress: any;
  downloadId: string | undefined;
  accessControls!: RbacInfo;
  displayDownloadingIcon: boolean = false;
  downloadProgressStatus: string = "0% Downloaded \nDownload Starting..."
  configurePopupOrder!: any;
  defaultFilterPredicate?: (data: any, filter: string) => boolean;
  p: number = 1;
  filterForm: FormGroup;
  filterChipMap: Map<string, string> = new Map<string, string>();

  paginatedData: any[] = [];
  totalRecords = 0;
  rowsPerPage = 10;
  visibleRangeLength=3;

  constructor(private integrationService: IntegrationService,
    private spinnerOverlayService: SpinnerOverlayService,
    private planService: PlanService,
    public dialog: MatDialog,
    private historyService: HistoryLogService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private formBuilder: FormBuilder) {
      this.filterForm = this.formBuilder.group({
        selectedOptions: [[]]
      })
  }

  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
    this.showConfigPopup = false;
  }

  sharedData: string | undefined;

  ngOnInit(): void {
    this.loadData();
    // this.mapDisplayColumnNames.set('type', 'Type');
    this.mapDisplayColumnNames.set('filename', 'Artifact');
    // this.mapDisplayColumnNames.set('friendlyname','Friendly Name');
    this.mapDisplayColumnNames.set('revision', 'Revision');
    // this.mapDisplayColumnNames.set('contract','Contract');
    this.mapDisplayColumnNames.set('artifactid', 'Artifact ID');
    this.mapDisplayColumnNames.set('docType', 'Doc Group');
    this.mapDisplayColumnNames.set('mappedEntities', 'Entity')
    // this.mapDisplayColumnNames.set('courseid','Course ID');
    // this.mapDisplayColumnNames.set('draft','Draft');
    // this.mapDisplayColumnNames.set('actions','Actions');
    this.configurePopupOrder = this.commonService.originalOrder

    this.paginationControl.valueChanges.subscribe((val) => this.onPageChange(val as PaginationValue));
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort || null;
    // this.dataSource.paginator = this.paginator || null;
    // this.sharedService.permissions$.subscribe((rbacInfo: RbacInfo) => {
    //   this.accessControls = rbacInfo;
    // });
    this.sharedService.permissions$.subscribe((rbacInfo: RbacInfo) => {
      this.accessControls = rbacInfo;
    });
  }

  columnSearch(key: string): boolean {
    const searchableColumns = ['artifact', 'artifactid', 'courseid', 'doctype'];
    if (key.indexOf(':') > 0) {
      if (searchableColumns.filter((col) => col.toLowerCase().includes(key.split(':')[0].toLowerCase())).length > 0) {
        return true;
      }
    }
    return false;
  }

  applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    if (this.columnSearch(filterValue)) {
      let columnName = filterValue.split(':')[0].trim().toLowerCase();
      let filteredArray: string[] = filterValue.split(':').slice(1);
      filterValue = filteredArray.length > 1 ? filteredArray.join(':') : filteredArray[0];
      const key = filterValue.trim().toLowerCase();
      this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
        let matchingString = undefined;
        if (columnName == "artifact") {
          matchingString = data.filename;
        } else if (columnName == 'artifactid') {
          matchingString = data.artifactid;
        } else if (columnName == 'courseid') {
          matchingString = data.courseid;
        } else if (columnName == 'doctype') {
          matchingString = data.docType;
        }
        return matchingString.toLowerCase().indexOf(key) >= 0;
      }
      this.dataSource.filter = key;
    } else {
      this.dataSource.filterPredicate = this.defaultFilterPredicate;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    this.onFilteringData();
    // setTimeout(() => {
    //   this.triggerPaginatorFalseClick();
    // }, 10);
  }

  pageChangeEvent(event: PageEvent) {
    this.dataSource.paginator = this.paginator;
  }

  toggleConfigPopup(event: Event) {
    event.stopPropagation();
    this.showConfigPopup = !this.showConfigPopup
  }

  configureCheckToggle(prop: string, flag: Event) {
    this.mapColumns.set(prop, !flag);
    this.columns = [...new Map([...this.mapColumns].filter(([key, value]) => value)).keys()];
    if (flag) {
      this.removeAllKeyRelatedToColumn(prop);
    }
  }
  isTeamApproved(approvals: { approved: boolean; approverRole: string }[], team: string): boolean {
    const teamApproval = approvals?.find(approval => approval?.approverRole === team);
    return teamApproval ? teamApproval.approved : false;
  }
  isTeamRequired(approvals: { approved: boolean; approverRole: string }[], team: string): boolean {
    return approvals != undefined ? approvals.some(approval => approval?.approverRole === team) : false;
  }
  getApprovalTypeId(approvals: { approved: boolean; approverRole: string; approvalTypeId: number }[], approverRole: string): number | null {
    for (const approval of approvals) {
      if (approval.approverRole === approverRole) {
        return approval.approvalTypeId;
      }
    }
    return null;
  }

  loadData(): void {
    this.spinnerOverlayService.show();
    this.integrationService.getAllAirlineArtifactMapping().subscribe(

      (data: commitmentResponse) => {
        if (data) {
          data.data.forEach((value: ArtifactMapping) => {
            value.headers.forEach(header => {
              this.approvalHeaders.add(header);
            });
          });
          this.approvalHeaders.forEach(header => { this.mapDisplayColumnNames.set(header.toLowerCase(), header) });
          // this.mapDisplayColumnNames.set('ready', 'Approved');
          if (this.shouldFreshDisplay) {
            this.mapDisplayColumnNames.set('fresh', 'Fresh');
          }
          this.mapDisplayColumnNames.set('artifactid', 'Artifact ID');
          this.mapDisplayColumnNames.set('actions', 'Actions');
          this.columns = [...this.mapDisplayColumnNames.keys()];
          let selectedValues: any = {};
          this.columns.map(column => {
            this.mapColumns.set(column, true);
            selectedValues[column] = [[]];
          });
          this.filterForm = this.formBuilder.group(selectedValues);
          this.ELEMENT_DATA = data.data.map((item, index) => ({
            position: index + 1,
            entity: item.airlineCode,
            artifactid: item.artifactId,
            filename: item.fileName,
            docType: item.docType,
            // extension: this.commonService.getIconValueFromFilename(item.fileName),
            extension: item.fileName ? this.commonService.getIconValueFromFilename(item.fileName) : "doc",
            friendlyname: '737 MAX Post Flight Package',
            courseid: '123456',
            updatedby: item.updatedBy,
            revision: item.revision,
            contract: item.contract,
            approvals: item.approvals,
            mappedEntities: item.mappedEntities,
            readyRequired: item.approvals.length === 0 ? true : false,
            Ready: this.ReadyFlag(item),
            fresh: item.isFresh,
            active: item.isActive,
            isLatest: item.isLatest
          }));

        this.visibleItems.data=this.ELEMENT_DATA;
        this.visibleItems.visibleItems = this.ELEMENT_DATA.slice(0, this.pagination.pageSize);
        this.visibleItems.total = this.ELEMENT_DATA.length;
        this.VisibleDataSource = new MatTableDataSource<PeriodicElement>(this.visibleItems.visibleItems)
        this.visibleRangeLength = Math.min(Math.ceil(this.visibleItems.total / this.pagination.pageSize), 3);


        this.dataSource = new MatTableDataSource<PeriodicElement>(this.visibleItems.data)
          this.pageLength = this.ELEMENT_DATA.length;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.defaultFilterPredicate = this.dataSource.filterPredicate;
          this.filterData = this.dataSource?.filteredData;
        }
        this.spinnerOverlayService.hide();
        this.dataLoad = true;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.spinnerOverlayService.hide();
      }
    );
  }

  getUniqueFilterOptionsBasedOnKey(key: any) {
    let result = [...new Set(this.filterData.map(function(row: any) {return row[key.key]}))];
    return result;
  }

  filterEvent(event: any, key: String) {
    let dataCopy = this.filterData;
    for (let [key, value] of Object.entries(this.filterForm.value)) {
      const currentDataCopy: any[] = [];
      if (Array.isArray(value) && value.length > 0) {
        value.forEach(filterValue => {
          currentDataCopy.push(...dataCopy.filter((obj: { [x: string]: any; }) => obj[key] == filterValue))
        });
        dataCopy = currentDataCopy;
      }
    }
    this.dataSource.data = dataCopy;
    this.onFilteringData();
  }

  isAnyFilterSelected() {
    return this.filterChipMap.size > 0;
  }

  checkBoxSelection(event: any, key: string) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.filterChipMap.set(event.source.value, key);
      } else {
        this.filterChipMap.delete(event.source.value);
      }
    }
  }

  removePill(key: any) {
    this.filterChipMap.delete(key);
    this.rearrangeFilter(this.filterChipMap);
  }

  removeAllKeyRelatedToColumn(column: any) {
    this.filterChipMap = new Map([...this.filterChipMap].filter(([key, value]) => value != column));
    this.rearrangeFilter(this.filterChipMap);
  }

  rearrangeFilter(filterChipMapValues: Map<string, string>) {
    let keyValueMap = new Map();
    filterChipMapValues.forEach((value, key) => {
      if (keyValueMap.has(value)) {
        keyValueMap.get(value).push(key);
      } else {
        keyValueMap.set(value, [key]);
      }
    });
    let dataCopy = this.filterData;
    for (let [key, value] of keyValueMap) {
      const currentDataCopy: any[] = [];
      if (Array.isArray(value) && value.length > 0) {
        value.forEach(filterValue => {
          currentDataCopy.push(...dataCopy.filter((obj: { [x: string]: any; }) => obj[key] == filterValue))
        });
        dataCopy = currentDataCopy;
      }
    }
    this.dataSource.data = dataCopy;
    this.onFilteringData();
  }

  getStringType(val: any) {
    return String(val);
  }

  clearFilter() {
    this.dataSource.data = this.filterData;
    this.filterChipMap.clear();
    for (let [key, value] of this.mapColumns) {
      this.filterForm.controls[key].reset();
    }
    this.onFilteringData();
  }

  isColumnFilterSelected(key: any) {
    if (this.filterChipMap.size > 0) {
      for (let val of this.filterChipMap.values()) {
        if (val == key) {
          return true;
        }
      }
    }
    return false;
  }

  onClickRowDetailsCopyAction(row: any) {
    this.spinnerOverlayService.show();
    let dataToCopy: any = {
      type: row.extension,
      artifact: row.filename,
      revision: row.revision,
      readyApproved: row.readyApproved,
      artifactid: row.artifactid,
      //courseid: row.courseid,
      //readyRequired: row.readyRequired,
    };
    navigator.clipboard.writeText(JSON.stringify(dataToCopy, undefined, '\t'));
    this.btlSharedLibraryService.updateStatusMessage(true, "Row data copied.", true);
    this.spinnerOverlayService.hide();
  }

  onClickFileAction(row: any) {
    this.downloadId = row.artifactid;
    this.displayDownloadingIcon = true;
    this.loaded = "";
    this.total = "";
    this.percentDone = 0;
    this.planService.downloadArtifact(row.artifactid, row.docType).subscribe(result => {
      this.downloadProgress = new DownloadProgress
      if (result.type === HttpEventType.DownloadProgress) {
        this.total = this.formatBytes(result.total);
        this.loaded = this.formatBytes(result.loaded);
        if (result.total && result.total > 0) {
          this.percentDone = Math.round(100 * result.loaded / result.total);
          this.downloadProgressStatus = this.percentDone + "% Downloaded \n" + this.loaded + " / " + this.total;
        }
      }
      if (result.type === HttpEventType.Response) {
        this.downloadProgress = undefined;
        importedSaveAs(result.body, row.filename);
        this.displayDownloadingIcon = false;
        this.downloadId = undefined;
        this.downloadProgressStatus = "0% Downloaded \nDownload Starting...";
        let logData = {
          "entity": row.entity ? row.entity : "",
          "filename": row.filename ? row.filename : "",
          "revision": row.artifactid.split(":")[1],
          "artifactId": row.artifactid,
          "actionType": "Artifact downloaded",
          "createdBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
          "modifiedBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
          "type": "APPROVAL"
        };
        this.newHistoryLogRecord(logData);
      }
    },
      error => {
        this.downloadProgressStatus = "0% Downloaded \nDownload Starting...";
        this.displayDownloadingIcon = false;
        this.downloadId = undefined;
        this.btlSharedLibraryService.updateStatusMessage(true, "Download Failed.", false);
        console.log('ERR', error);
      }
    );
  }

  onCheckboxChange(row: any, contentKey: string, team: string) {
    this.spinnerOverlayService.show();
    this.rowData = row;
    const requestBody: any = {};
    requestBody.contentKey = contentKey;
    requestBody.approvalId = this.getApprovalTypeId(row.approvals, team)
    requestBody.approvedBy = "BTL";
    this.integrationService.updateApprovalCheckboxState(requestBody).subscribe(
      response => {
        this.rowData.actionType = this.isTeamApproved(row.approvals, team) ? team + " column unchecked" : team + " column checked";
        this.checkBoxStateChange(this.rowData);
        this.loadData();
        this.spinnerOverlayService.hide();
      },
      error => {
        this.loadData();
        this.spinnerOverlayService.hide();
      }
    );
  }
  ReadyFlag(item: any) {
    if (item.approvals.length === 0) {
      return item.isReady;
    } else {
      return (
        item.approvals.every((approval: { approverRole: string; approved: boolean; approvalTypeId: number }) => approval.approved)
      );
    }
  }

  checkBoxStateChange(rowData: any) {
    let logData = {
      "entity": rowData.entity ? rowData.entity : "",
      "filename": rowData.filename ? rowData.filename : "",
      "revision": rowData.artifactid.split(":")[1],
      "artifactId": rowData.artifactid,
      "actionType": rowData.actionType,
      "createdBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
      "modifiedBy": this.sharedService.getUserInfo().email ? this.sharedService.getUserInfo().email : "BTL admin",
      "type": "APPROVAL"
    };
    this.newHistoryLogRecord(logData);
  }

  onFreshCheckboxChange(row: any) {
    this.rowData = row;
    row.fresh = !row.fresh;
    this.rowData.actionType = !row.fresh ? "Fresh column unchecked" : "Fresh column checked";
    console.log(this.rowData.actionType);
    this.checkBoxStateChange(this.rowData);
    this.integrationService.updateFreshCheckboxState(row.artifactid, row.fresh).subscribe(
      response => {
        console.log(response);
        this.loadData();
        this.spinnerOverlayService.hide();
      },
      error => {
        this.loadData();
        console.error("Error occurred while updating fresh checkbox state:", error);
        this.spinnerOverlayService.hide();
      }
    );
  }

  newHistoryLogRecord(payload: any) {
    this.historyService.newRecordAddedLog(payload).subscribe(
      (response) => {
        console.log("Successfully Logged");
      },
      (error) => {
        console.log("Logging error!!");
      }
    );
  }

  shouldDisplayCheckbox(row: any, header: string): boolean {
    return row.approvals.some((approval: { approverRole: string; approved: boolean; approvalTypeId: number }) => approval.approverRole === header);
  }

  isCheckboxTicked(row: any, header: string): boolean {
    const matchingApproval = row.approvals.find((approval: { approverRole: string; approved: boolean; approvalTypeId: number }) => approval.approverRole === header && approval.approved);
    return !!matchingApproval;
  }

  openHistoryDialog(row: any): void {
    let logData = {
      "artifactid": row.artifactid,
      "filename": row.filename,
      "type": "APPROVAL",
      "title": "Approvals History"
    };
    const dialogRef = this.dialog.open(HistoryComponent, { autoFocus: false, data: logData });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Byte';

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  stringExists(text: any): boolean {
    return (text != undefined && text.length > 0);
  }

  triggerPaginatorFalseClick() {
    this.paginator?.nextPage();
    this.paginator?.previousPage();
    this.paginator?.page.next({
      pageIndex: 1,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    });
  }

  isApprovedDisabled(readyRequired: boolean) {
    return this.isReadOnly || !readyRequired || this.disablePermissions(this.modules.approval_approved);
  }

  isFreshDisabled(isLatest: boolean) {
    if (this.accessControls?.permissions.indexOf(Modules[this.modules.approval_fresh]) > -1) {
      return !isLatest;
    }
    return true;
  }

  disablePermissions(module: Modules) {
    return this.accessControls?.permissions.indexOf(Modules[module]) === -1
  }

  openMetadataDialog(row: any): void {
    let data = {
      "docType": row.docType,
      "documentid": row.artifactid
    }
    const dialogRef = this.dialog.open(MetadataDialogComponent, { autoFocus: false, data: data });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }
public onFilteringData(): void {
  this.pagination.page = 0;
    const startIndex = 0;
    this.visibleItems.data=this.dataSource.filteredData;
    this.visibleItems.total=this.visibleItems.data.length;
    const items = this.visibleItems.data.slice(
      startIndex,
      startIndex + this.pagination.pageSize
    );
    this.visibleItems.visibleItems = items;
    this.VisibleDataSource = new MatTableDataSource<PeriodicElement>(this.visibleItems.visibleItems)
    this.visibleRangeLength = Math.min(Math.ceil(this.visibleItems.total / this.pagination.pageSize), 3);

  }
  public onPageChange(pagination: PaginationValue): void {
    const startIndex = (pagination.page - 1) * pagination.pageSize;

    const items = this.visibleItems.data.slice(
      startIndex,
      startIndex + pagination.pageSize
    );

    this.visibleItems.visibleItems = items;
    this.VisibleDataSource = new MatTableDataSource<PeriodicElement>(this.visibleItems.visibleItems)

  }
  paginate(page: number): void {
    this.p=page;
  }
}





import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormatControlNamePipe } from 'src/app/pipes/format-control-name-pipe';
import { IntegrationService } from 'src/app/services/integration.service';
import { SharedService } from 'src/app/services/shared.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay.service';

@Component({
  selector: 'app-metadata-dialog',
  templateUrl: './metadata-dialog.component.html',
  styleUrls: ['./metadata-dialog.component.scss'],
  providers : [FormatControlNamePipe]
})
export class MetadataDialogComponent implements OnInit {

  popupData : any;
  timezone : any;
  fileName : any;
  artifactId : any;
  courseType : any;
  documentType : any;
  revisionDate : any;
  regulatoryAgency : any;
  documentNumber : any;
  customerCode : any;
  asidCourseId : any;
  comments : any;
  revisionNumber : any;
  models : any;
  keywords : any;
  propertyForm!: FormGroup;
  propertyDefinitions: any;
  metadata : any;



  constructor(private integrationService: IntegrationService,
    private spinnerOverlayService: SpinnerOverlayService,
    private sharedService: SharedService,
    private formatControlName : FormatControlNamePipe,
    public dialogRef: MatDialogRef<MetadataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.popupData  = this.data
      this.propertyForm = new FormGroup({});     
     }

  ngOnInit(): void {
    this.loadData();
    this.loadPropertyDefinitions();
    this.sharedService.timeZone$.subscribe((zone => {
      this.timezone = zone;
    }))
    
  }

  loadPropertyDefinitions(): void {
    this.integrationService.getPropertyDefinitions(this.popupData.docType).subscribe((response: any) => {
      this.propertyDefinitions = response.doctypes[0].definition.filter((item : any) => item.propertyName != 'keywords');
      this.createForm();
      this.loadData();
    });
  }

  createForm(): void {
    this.propertyDefinitions.forEach((property: any) => {
      const control = new FormControl('', property.restriction === 'Mandatory' ? Validators.required : null);
      this.propertyForm.addControl(property.propertyName, control);
    });
  }

  populateForm(): void {
    Object.keys(this.propertyForm.controls).forEach((propertyName: string) => {
      if (this.metadata[propertyName]) {
        this.propertyForm.controls[propertyName].setValue(this.metadata[propertyName]);
      }
    });
  }

  loadData() : void{
    this.spinnerOverlayService.show();
      this.integrationService.getMetaData(this.popupData.docType,this.popupData.documentid).subscribe((data:any) => {
      console.log('data from api', data)
      console.log('data inside',data.data[0])
      this.metadata = data.data[0].metadata;
      this.populateForm();
      this.fileName = data.data[0].cmsObjectInfo.docName ? data.data[0].cmsObjectInfo.docName : data.data[0].cmsObjectInfo.documentName
      this.artifactId = data.data[0].contentKey
      this.keywords =(data.data[0].metadata.keywords !=null && data.data[0].metadata.keywords.length > 0 ) ? data.data[0].metadata.keywords.split(",") : []
      this.spinnerOverlayService.hide();
    },
    (error) => {
        console.error('Error fetching data:', error);
        this.spinnerOverlayService.hide();
    })

  }

  close() : void{
    this.dialogRef.close()
    
  }

}

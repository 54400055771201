import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HistoryMapping } from '../models/history.model';
import { RbacInfo, UserInfo } from '../models/modules.model';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private submitEventSource = new Subject<void>();

  submitEvent$ = this.submitEventSource.asObservable();

  private permissionsSubject = new Subject<RbacInfo>();
  permissions$ = this.permissionsSubject.asObservable();

  private adGroupsSubject = new Subject<string[]>();
  adGroups$ = this.adGroupsSubject.asObservable();

  private userInfoSubject = new Subject<User>();
  userInfo$ = this.userInfoSubject.asObservable();

  private timeZoneSubject = new Subject<string>();
  timeZone$ = this.timeZoneSubject.asObservable();

  private searchStringSubject = new Subject<string>();
  searchString$ = this.searchStringSubject.asObservable();

  private rbacInfo!: RbacInfo;
  private userInfo!: User;
  private adGroups!: string[];
  private timeZone!: string;
  private searchString!: string;


  triggersubmitEvent() {
    this.submitEventSource.next();
  }

  setPermissions(rbacInfo: RbacInfo) {
    this.rbacInfo = rbacInfo;
    this.permissionsSubject.next(rbacInfo);
  }

  getPermissions(){
    return this.rbacInfo;
  }

  setUserInfo(user: User) {
    this.userInfo = user;
    this.userInfoSubject.next(user);
  }

  getUserInfo(){
    return this.userInfo;
  }

  setTimeZone(timezone: string) {
    this.timeZone = timezone;
    this.timeZoneSubject.next(timezone);
  }

  setAdGroups(adGroups: string[]) {
    this.adGroups = adGroups;
    this.adGroupsSubject.next(this.adGroups);
  }

  getAdGroups(){
    return this.adGroups;
  }

  public IsUserContractEditorOrGreater(): boolean {
    return this.IsUserContractEditor() || this.IsUserAdmin();
  }

  public IsUserArtifactApproverOrGreater(): boolean {
    return this.IsUserArtifactApprover() || this.IsUserAdmin();
  }

  public IsUserContractEditor(): boolean {    
    return this.adGroups.indexOf(environment.RoleContractEditor) > -1;
  }

  public IsUserArtifactApprover(): boolean {
    return this.adGroups.indexOf(environment.RoleArtifactApprover) > -1;
  }

  public IsUserAdmin(): boolean {
    return this.adGroups.indexOf(environment.RoleAppAdmin) > -1;
  }

  public IsUserReadOnly(): boolean {
    return this.adGroups.indexOf(environment.RoleReadOnly) > -1;
  }

  setSearchString(searchString: string) {
    this.searchString = searchString;
    this.searchStringSubject.next(searchString);
  }

  getSearchString(): string {
    return this.searchString;
 }
}
<div class="full-height">
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <a href="#" class="navbar-brand">Boeing Training Library</a>

    <ul class="main-nav navbar-nav d-flex flex-row ml-auto">
      <li class="nav-item nav-link text-white">
        <i class="material-icons">apps</i>
      </li>
      <li class="nav-item nav-link text-white">
        <!-- <i class="material-icons">account_circle</i> -->
        <i class="material-icons" [matMenuTriggerFor]="profileMenu">account_circle</i>

        <mat-menu #profileMenu="matMenu" class="profile-menu">
          <div class="profile-info">
            <div class="full-name">{{user?.firstName}} {{user?.lastName}}</div>
            <div class="email">{{user?.email}}</div>
            <div class="airline">{{user?.airlineName}}</div>
          </div>

          <!-- <button class="menu-btn-white" (click)="logout()" mat-menu-item>Your MBF Account Details</button> -->
          <button class="menu-btn-primary" (click)="logout()" mat-menu-item>LOGOUT</button>
        </mat-menu>
      </li>
    </ul>
  </nav>
  <!--status message-->
  <lib-status-message></lib-status-message>
  <div class="container-fluid app-container px-0 d-flex justify-content-start">
    <router-outlet></router-outlet>
  </div>

</div>

import { KeyValue } from "@angular/common";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root',
})
export class CommonService {

    constructor(private snackBar: MatSnackBar) { }

    public readonly DOCUMENT_EXTENSIONS = ['doc', 'docx'];
    public readonly VIDEO_EXTENSIONS = ['mp4', 'avi'];
    public readonly PPT_EXTENSIONS = ['ppt', 'pptx'];
    public readonly PDF_EXTENSIONS = ['pdf'];
    public readonly ZIP_EXTENSIONS = ['zip'];
    public readonly AUDIO_EXTENSIONS = ['mp3'];

    getIconValueFromFilename(filename: string): string {
        const extension = filename.split('.').pop();
        if (extension) {
            const lowercaseExtension = extension.toLowerCase();
            const documentExtensions = this.DOCUMENT_EXTENSIONS;
            const videoExtensions = this.VIDEO_EXTENSIONS;
            const pptExtensions = this.PPT_EXTENSIONS;
            const pdfExtension = this.PDF_EXTENSIONS;
            const zipExtension = this.ZIP_EXTENSIONS;
            const audioExtension = this.AUDIO_EXTENSIONS;

            if (documentExtensions.includes(lowercaseExtension)) {
                return 'description'; // It's a document
            } else if (videoExtensions.includes(lowercaseExtension)) {
                return 'movie'; // It's a video
            }
            else if (pptExtensions.includes(lowercaseExtension)) {
                return 'co_present'; // It's a presentation
            } else if (pdfExtension.includes(lowercaseExtension)) {
                return 'picture_as_pdf';
            } else if (zipExtension.includes(lowercaseExtension)) {
                return 'folder_zip';
            } else if (audioExtension.includes(lowercaseExtension)) {
                return 'library_music';
            }

        }
        return 'draft'; // Default
    }

    showSuccessSnackBar(message: string): void {
        this.snackBar.open(message, 'Close', {
            duration: 2000,
            panelClass: ['success-snackbar'],
        });
    }

    showErrorSnackBar(message: string): void {
        this.snackBar.open(message, 'Close', {
            duration: 5000,
            panelClass: ['error-snackbar'],
        });
    }

    // Preserve original property order
    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    // Order by ascending property value
    valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return a.value.localeCompare(b.value);
    }

    // Order by descending property key
    keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
    }
}